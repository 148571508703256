.end {
  display: inline-block;
  font-family: 'Unica One', cursive;
  background-color: white;
  padding: 5px 0 0 0;
  width: 100%;
}

.contact-link{
  text-decoration: none;
  margin: 0 5px;

}


/*******************For iPhone*********************/

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .end {
    font-size: 12px;
  }
}