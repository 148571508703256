@import url(https://fonts.googleapis.com/css2?family=Unica+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mrs+Sheppards&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landing-container {
  background-image: url(https://media0.giphy.com/media/SigDhWbM4Io8g/source.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  color: white;
  display: grid;
  grid-template-rows: 10% 25% 1fr 10%;
  justify-content: center;
}

.name-holder {
  margin: 4em 0 0 1.25em;
  padding: 1em;
  /* background-color: rgba(255, 255, 0, 0.411); */
}

.name {
  font-family: 'Unica One', cursive;
  font-size: 80px;
  text-shadow: 1px 2px 3px yellow, 1px 4px 7px black;
}

.title {
  font-size: 40px;
  margin: -100px 0 0 396px;
  text-shadow: 1px 1px 4px black, 1px 2px 20px white;
  font-family: 'Advent Pro', sans-serif;
}

/*******************For iPhone*********************/

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {

  .name-holder {
    padding: 10px;
    margin: .25em 0 0 .25em;
  }

  .name {
    font-size: 40px;
  }
  
  .title {
    font-size: 20px;
    margin: -50px 0 0 143px;
  }


  .landing-container {
    height: 75vh;
    width: 90%;
  }

  Box {
    margin: 20px 5px 0 5px;
  }

  #about {

    font-size: 18px;
  }


}


    /*******************For iPad*********************/

    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 2) {

    }
.header-container {
  display: grid;
  grid-template-columns: 5% 1fr 5%;
}

.icon{
  height: 30px;
  width: 34px;
  margin: 8px 0 0 0;
  padding-left: 8px;
}

.nav {
  margin: 1em 0 2em 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100%;
 
}

.nav a {
  margin: 6px;
  color: transparent;
  text-decoration: none;
  font-family: 'Advent Pro', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-shadow: 1px 1px 1px #181A29;
}

.nav a:hover {
  font-size: 18.1px;
  text-shadow: 1px 1px 3px white, 1px 1px 1px black;
  transition: 1s;
}



#project-container {
height: 90vh;
width: 100%;
background-image: url(https://www.moundcotton.com/wp-content/uploads/background-nyc-skyline-01.jpg);
background-repeat: no-repeat;
background-position: center;
background-size: cover;
}
/*https://live.staticflickr.com/7407/9231089749_091fc80c91_b.jpg*/
.projects-header {
  font-family: 'Mrs Sheppards', cursive;
  font-weight: 100;
  background-color: rgba(216, 146, 187, 0.178);
  color:black;
  text-shadow: 1px 2px 2px white, 1px 1px 1px rgba(216, 146, 187);
  box-shadow: 1px 1px 1px rgba(216, 146, 187);
  margin: 0 0 10px 0;
}

#projects {
  color: black;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.project {
  font-family: 'Advent Pro', sans-serif;
  font-weight: 900;
  

}

.project-info {
  display: grid;
  grid-template-columns: 1fr 30%;
  /* height: 100%; */
  
}

.project-display {
  height: 100%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.24);
}

.tech-list {
  height: 98%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  justify-content: center;
  padding: 2px 0;
  box-shadow: 1px 3px 4px rgba(216, 146, 187) ;
  background-color: rgba(255, 255, 255, 0.267);
}

.project-heading {
text-align: left;
  padding: 8px;
  color:black;
  text-shadow: 1px 2px 1px white;
  background-color: rgba(253, 253, 253, 0.342);

}

.project-img a {
  height: 350px;
  width: 500px;
  box-shadow: 1px 1px 6px rgb(0, 0, 0);
}

.project-img a:hover {
  box-shadow: 6px 6px 8px rgb(0, 0, 0);
}


#resume-container {
  height: 90vh;
  background-image: url(https://images.fineartamerica.com/images-medium-large/ed-koch-bridge-s-paul-sahm.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.resume-header {
  font-family: 'Mrs Sheppards', cursive;
  font-weight: 100;
  background-color: rgba(216, 146, 187, 0.178);
  color:black;
  text-shadow: 1px 2px 2px white, 1px 1px 1px #6A4887;
  box-shadow: 1px 1px 1px rgba(216, 146, 187);
  margin: 0 0 10px 0;
}

#resume {
margin: 38px 0;
height: 600px;
width: 500px;
box-shadow: 1px 2px 8px black, 1px 1px 5px white;
}
#contact {
  height: 90vh;
  margin: 0 auto;
  background-image: url(https://live.staticflickr.com/4524/26742244799_10fab3c994_b.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.contact-form {
  margin: 10em 27em 0 27em;
  padding: 5em 0;
  background-color: rgba(255, 255, 255, 0.315);
  box-shadow: 1px 2px 5px white, 1px 1px 1px black;
  border-radius: 6px;
  width: 40%;
  font-family: 'Unica One', cursive;
  color: white;
  font-weight: 600;
  text-shadow: 1px 1px 1px black, 1px 1px 1px white;
  
}

.contact-header {
  font-family: "Mrs Sheppards", cursive;
  font-weight: 100;
  background-color: rgba(216, 146, 187, 0.178);
  color: white;
  text-shadow: 1px 2px 2px black, 1px 1px 1px #6a4887;
  box-shadow: 1px 1px 1px rgba(216, 146, 187);
  margin: 0 0 10px 0;
}


#username {
  margin: 5px;
}

#email {
  margin: 5px;
}

#inquiry {
 
}

.submit-button {
  margin: 10px;
  padding: 1em;
}
.end {
  display: inline-block;
  font-family: 'Unica One', cursive;
  background-color: white;
  padding: 5px 0 0 0;
  width: 100%;
}

.contact-link{
  text-decoration: none;
  margin: 0 5px;

}


/*******************For iPhone*********************/

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .end {
    font-size: 12px;
  }
}
html {
  scroll-behavior: smooth;
}


.app {
  text-align: center;
  height: 110v;
  margin: -1.15em 0 0 0 ;
  /* background-color: red; */
 
}


