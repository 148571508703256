html {
  scroll-behavior: smooth;
}


.app {
  text-align: center;
  height: 110v;
  margin: -1.15em 0 0 0 ;
  /* background-color: red; */
 
}

