@import url("https://fonts.googleapis.com/css2?family=Unica+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mrs+Sheppards&display=swap");

.landing-container {
  background-image: url(https://media0.giphy.com/media/SigDhWbM4Io8g/source.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  color: white;
  display: grid;
  grid-template-rows: 10% 25% 1fr 10%;
  justify-content: center;
}

.name-holder {
  margin: 4em 0 0 1.25em;
  padding: 1em;
  /* background-color: rgba(255, 255, 0, 0.411); */
}

.name {
  font-family: 'Unica One', cursive;
  font-size: 80px;
  text-shadow: 1px 2px 3px yellow, 1px 4px 7px black;
}

.title {
  font-size: 40px;
  margin: -100px 0 0 396px;
  text-shadow: 1px 1px 4px black, 1px 2px 20px white;
  font-family: 'Advent Pro', sans-serif;
}

/*******************For iPhone*********************/

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {

  .name-holder {
    padding: 10px;
    margin: .25em 0 0 .25em;
  }

  .name {
    font-size: 40px;
  }
  
  .title {
    font-size: 20px;
    margin: -50px 0 0 143px;
  }


  .landing-container {
    height: 75vh;
    width: 90%;
  }

  Box {
    margin: 20px 5px 0 5px;
  }

  #about {

    font-size: 18px;
  }


}


    /*******************For iPad*********************/

    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (-webkit-min-device-pixel-ratio: 2) {

    }